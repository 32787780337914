// Api
import api from '../helpers/api'
// Actions
import { endSpinner, startSpinner } from '../redux/reducers/spinnerReducer'

export const SET_LOADING = 'SET_LOADING'
export const SAVE_ORDER_DATA = 'SAVE_ORDER_DATA'
export const CLEAR_ORDER_DATA = 'CLEAR_ORDER_DATA'
export const SET_EMPTY_DATA_FLAG_ISSUER = 'SET_EMPTY_DATA_FLAG_ISSUER'

const defaultState = {
  ordersData: [],
  isDataEmpty: false,
  loading: true
}

// Reducer
export default (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_ORDER_DATA: {
      return Object.assign({}, state, {
        ordersData: defaultState.ordersData
      })
    }
    case SAVE_ORDER_DATA: {
      return Object.assign({}, state, {
        ...state,
        ordersData: action.payload
      })
    }
    case SET_EMPTY_DATA_FLAG_ISSUER: {
      return Object.assign({}, state, {
        ...state,
        isDataEmpty: action.payload
      })
    }
    case SET_LOADING: {
      return Object.assign({}, state, {
        ...state,
        loading: action.payload
      })
    }
    default:
      return state
  }
}

export const clearOrders = () => {
  return {
    type: CLEAR_ORDER_DATA
  }
}

export const saveOrders = (ordersData) => {
  return {
    type: SAVE_ORDER_DATA,
    payload: ordersData
  }
}

export const saveDataEmpty = (isDataEmpty) => {
  return {
    type: SET_EMPTY_DATA_FLAG_ISSUER,
    payload: isDataEmpty
  }
}

export const setLoading = (loading) => {
  return {
    type: SET_LOADING,
    payload: loading
  }
}

export const getOrders = () => async (dispatch) => {
  return await api
    .get('orders')
    .then((response) => {
      const { data, status } = response.data
      dispatch(saveOrders(data))
      if (status === 200) {
        dispatch(saveDataEmpty(true))
        dispatch(setLoading(false))
      }
    })
    .catch((e) => {
      console.log('Error calling getOrders: ', e)
    })
}

export const issueAwardsForBadge = async ({
  orderId,
  badgeId,
  awards,
  successCb,
  errorCb,
  dispatch
}) => {
  dispatch(startSpinner())

  return await api
    .post(`orders/${orderId}/awards`, { badgeId, awards })
    .then((response) => {
      const { data } = response.data
      successCb && successCb(data)
      dispatch(endSpinner())
    })
    .catch((error) => {
      console.log('Error calling issueAwardsForBadge: ', error)
      errorCb && errorCb(error.response.status)
    })
}

export const deleteOrdersById = async ({ orderIds, successCb, errorCb, dispatch }) => {
  dispatch(startSpinner())

  return await api
    .delete('orders/delete', { orderIds })
    .then(() => {
      successCb && successCb()
      dispatch(endSpinner())
    })
    .catch((error) => {
      console.log('Error calling deleteOrdersById: ', error)
      errorCb && errorCb(error.response.status)
    })
}
