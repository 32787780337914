import styled from 'styled-components'
import colors from '../../../../helpers/colors'

export const TableHeader = styled.thead`
  tr {
  }
  th {
    padding: 10px 15px !important;
    text-align: left;
    font-weight: bold;

    label {
      display: inline;
    }
  }
`

export const TableBody = styled.tbody`
  td {
    padding: 10px 15px !important;
    border: none;

    span.awards {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      font-size: 0.875rem;
      font-weight: 500;
      color: ${colors.white};
      background: ${colors.blueyGrey};
    }
  }
`

export const StartButton = styled.button`
  background-color: ${colors.blue};
  color: white;
  padding: 7px 35px;
  border-radius: 18px;
  border: none;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #0037cc;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #004aff33;
  }

  &:active {
    transform: translateY(1px);
  }

  &:disabled {
    background-color: #e5e5e5;
    cursor: not-allowed;
  }
`
