/**
 * Converts a date into "dd.mm.yyyy" format. It can adjust the date to the next day and
 * also handle Unix timestamp input.
 *
 * @param {string | number} date - The date to be formatted. Can be a date string or Unix timestamp.
 * @param {boolean} flagForNextDay - If true, shifts the date to the next day.
 * @param {boolean} timeInSecondFlag - If true, treats the input date as a Unix timestamp in seconds.
 * @returns {string} The formatted date as a string in "dd.mm.yyyy" format.
 */

export const convertDateFormat = (date, flagForNextDay = false, timeInSecondFlag = false) => {
  // eslint-disable-next-line prefer-const
  let d = timeInSecondFlag ? new Date(date * 1000) : new Date(date)

  if (flagForNextDay) {
    d.setDate(d.getDate() + 1)
  }

  const day = String(d.getDate()).padStart(2, '0')
  const month = String(d.getMonth() + 1).padStart(2, '0')
  const year = d.getFullYear()

  return `${day}.${month}.${year}`
}

/**
 * Formats a date into a specified format.
 *
 * @param {string | number | Date} date - The date to be formatted. Can be a date string, Unix timestamp (milliseconds), or Date object.
 * @param {string} locale - The locale for formatting (default: "de-DE").
 * @param {Object} formatOptions - The format options for Intl.DateTimeFormat (default: { day: "2-digit", month: "long", year: "numeric" }).
 * @returns {string} The formatted date as a string.
 */

export const formatDate = (
  date,
  locale = 'de-DE',
  formatOptions = { day: '2-digit', month: 'long', year: 'numeric' }
) => {
  const d = typeof date === 'number' ? new Date(date) : new Date(date)

  // If the date is invalid, return an empty string (or you can return null)
  if (isNaN(d)) {
    return ''
  }

  return new Intl.DateTimeFormat(locale, formatOptions).format(d)
}
