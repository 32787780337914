// Modules
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'
// Components
import Checkbox from '../../../../components/Checkbox'
import { StartButton, TableBody, TableHeader } from './OrdersListStyled'
// Helpers
import { formatDate } from '../../../../helpers/dates'

const OrdersList = ({
  orders,
  selectedOrders,
  handleOrderGroupSelection,
  updateSelectedOrders,
  setOrderForApproval
}) => {
  const { t } = useTranslation()

  const areAllOrdersSelected = selectedOrders?.length === orders.length
  const areSomeOrdersSelected = selectedOrders?.length > 0 && selectedOrders?.length < orders.length

  const handleToggleAllOrdersSelection = useCallback(() => {
    handleOrderGroupSelection(areAllOrdersSelected, areSomeOrdersSelected)
  }, [handleOrderGroupSelection, areAllOrdersSelected, areSomeOrdersSelected])

  const handleOrderCheckboxClick = useCallback(
    (orderId) => {
      updateSelectedOrders({
        operation: selectedOrders && selectedOrders.includes(orderId) ? 'unselect' : 'select',
        orderId
      })
    },
    [selectedOrders, updateSelectedOrders]
  )

  return (
    <>
      <Table responsive hover borderless className="custom-table">
        <TableHeader>
          <tr>
            <th>
              <Checkbox
                checked={areAllOrdersSelected}
                indeterminate={areSomeOrdersSelected}
                onClick={handleToggleAllOrdersSelection}
              />
            </th>
            <th style={{ width: '20%' }}>{t('awardOrders.listing.table.course-title')}</th>
            <th style={{ width: '20%' }}>{t('awardOrders.listing.table.course-start-date')}</th>
            <th style={{ width: '20%' }}>{t('awardOrders.listing.table.course-end-date')}</th>
            <th style={{ width: '20%' }}>{t('awardOrders.listing.table.issuer')}</th>
            <th style={{ width: '20%' }}>{t('awardOrders.listing.table.request-date')}</th>
            <th style={{ textAlign: 'center' }}>{t('awardOrders.listing.table.awards')}</th>
            <th></th>
          </tr>
        </TableHeader>

        <TableBody>
          {orders && orders.length > 0 ? (
            orders.map((order, i) => (
              <tr key={i}>
                <td>
                  <Checkbox
                    checked={selectedOrders?.includes(order.id)}
                    onClick={() => handleOrderCheckboxClick(order.id)}
                  />
                </td>
                <td>{order.courseTitle}</td>
                <td>
                  {formatDate(order.courseStart, 'de-DE', {
                    month: 'long',
                    day: '2-digit',
                    year: 'numeric'
                  })}
                </td>
                <td>
                  {formatDate(order.courseEnd, 'de-DE', {
                    month: 'long',
                    day: '2-digit',
                    year: 'numeric'
                  })}
                </td>
                <td>{order.issuer?.name}</td>
                <td>
                  {formatDate(order.requestTimestamp, 'de-DE', {
                    month: 'long',
                    day: '2-digit',
                    year: 'numeric'
                  })}
                </td>
                <td style={{ textAlign: 'center' }}>
                  <span className="awards">{order.awards?.length}</span>
                </td>
                <td>
                  <StartButton onClick={() => setOrderForApproval(order)}>
                    {t('awardOrders.listing.buttons.start')}
                  </StartButton>
                </td>
              </tr>
            ))
          ) : (
            <>
              <tr>
                <td colSpan="8" style={{ textAlign: 'center' }}>
                  {t('awardOrders.listing.messages.no-available-orders')}
                </td>
              </tr>
            </>
          )}
        </TableBody>
      </Table>
    </>
  )
}

OrdersList.propTypes = {
  orders: PropTypes.array,
  selectedOrders: PropTypes.array,
  handleOrderGroupSelection: PropTypes.func,
  updateSelectedOrders: PropTypes.func
}

export default OrdersList
