// Modules
import React, { useState, useRef } from 'react'
// Hooks
import useAuthRedirection from './hooks/useAuthRedirection'
// Helpers
import { updateLocalStorage } from '../../../helpers/localStorage'
// Components
import { IssuerList, FilterBox } from './components'
import { SearchBox, PaginationInfiniteScroll } from '../../../components'
// Config
import { hasPermission } from '../../../helpers/permissions'
import StatsBox from '../../../components/StatsBox/StatsBox'

export const ISSUERS_PER_PAGE = 20

const IssuerListing = ({ t, auth, issuers, stats }) => {
  const wrapperFilter = useRef(null)

  const issuersLoop = issuers?.slice(0, ISSUERS_PER_PAGE)
  const [listIssuers, setListIssuers] = useState([])
  const [state, setState] = useState({
    isActive: false,
    isFocus: false,
    hasMore: true,
    searchQuery: '',
    searchFilters: undefined
  })

  useAuthRedirection(auth, issuers, setState, setListIssuers, issuersLoop)

  const handleLoadMoreIssuers = () => {
    if (listIssuers.length >= issuers?.length) {
      setState((prevPageData) => ({
        ...prevPageData,
        hasMore: false
      }))
      return
    }
    const newIssuers = issuers?.slice(listIssuers.length, listIssuers.length + ISSUERS_PER_PAGE)

    return new Promise((resolve) => {
      setTimeout(() => {
        setListIssuers([...listIssuers, ...newIssuers])
        resolve()
      }, 300)
    })
  }

  const handleSearchChange = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const { value } = event.target

    if (!value) {
      setListIssuers(issuers)
    }

    const filteredIssuers = issuers?.filter((issuer) =>
      issuer.name.toLowerCase().includes(value.toLowerCase())
    )
    setListIssuers(filteredIssuers)
  }

  const handleSearchOnFocus = () => {
    setState((prevPageData) => ({
      ...prevPageData,
      isFocus: true
    }))
  }

  const prepareIssuersFilter = () => {
    const filters = []

    issuers?.forEach((issuer) => {
      filters.push({
        id: issuer.id,
        value: issuer.name,
        label: issuer.name
      })
    })

    return filters
  }

  const showFilter = () => {
    setState((prevPageData) => ({
      ...prevPageData,
      isActive: true
    }))
  }

  const hideFilter = () => {
    setState((prevPageData) => ({
      ...prevPageData,
      isActive: false
    }))
  }

  const cancelFilter = () => {
    hideFilter()
    setState((prevPageData) => ({
      ...prevPageData,
      searchFilters: null
    }))
    setListIssuers(issuers)
    updateLocalStorage({ item: 'searchFilters', doDelete: true })
  }

  const handleIssuersFilter = (value) => {
    if (value.length > 0) {
      setState((prevPageData) => ({
        ...prevPageData,
        searchFilters: value
      }))
      const filteredIds = value.map((ele) => ele.id)
      const filtered = issuers?.filter((issuer) => filteredIds.includes(issuer.id))
      setListIssuers(filtered)

      updateLocalStorage({ item: 'searchFilters', value })
    } else {
      cancelFilter()
    }
  }

  const IssuerData = ({ listIssuers, loadMore, hasMore }) => (
    <div>
      <PaginationInfiniteScroll
        dataLength={listIssuers.length}
        loadMore={loadMore}
        hasMore={hasMore}
        scrollableTargetId="issuerScroll"
        classNameScroll="issuer-card-data">
        <IssuerList listIssuers={listIssuers} />
      </PaginationInfiniteScroll>
    </div>
  )

  return (
    <>
      <div className="search-filter-data">
        <SearchBox
          t={t}
          isFocus={state.isFocus}
          handleSearchChange={handleSearchChange}
          onFocus={handleSearchOnFocus}
        />

        <StatsBox {...stats} />
        <div>
          <FilterBox
            t={t}
            role={auth?.role}
            wrapperFilter={wrapperFilter}
            isActive={state.isActive}
            options={prepareIssuersFilter()}
            showFilter={showFilter}
            hideFilter={hideFilter}
            onChangeOfFilter={handleIssuersFilter}
            hasPermission={hasPermission}
            searchFilters={state.searchFilters}
          />
        </div>
      </div>

      <IssuerData
        listIssuers={listIssuers}
        loadMore={handleLoadMoreIssuers}
        hasMore={state.hasMore}
      />
    </>
  )
}

export default IssuerListing
