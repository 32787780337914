// Styled Components
import {
  DeleteButton,
  FilterAndActionButtonsContainer,
  FilterButton
} from '../AwardOrdersListing/AwardOrdersListingStyled'
// Icons
import IconFilter from '../../../assets/image/icon-filter.svg'
import IconTrash from '../../../assets/image/icon-delete.svg'

const FilterAndActions = ({
  filterRef,
  setFilterOptions,
  setIsDeleteModalOpen,
  selectedOrders
}) => {
  return (
    <FilterAndActionButtonsContainer ref={filterRef}>
      <FilterButton onClick={() => setFilterOptions((prev) => ({ ...prev, isOpen: !prev.isOpen }))}>
        Filter
        <img src={IconFilter} className="img-fluid" alt="icon-filter" />
      </FilterButton>
      <DeleteButton
        disabled={selectedOrders.length === 0}
        onClick={() => setIsDeleteModalOpen(true)}>
        <img src={IconTrash} className="img-fluid" alt="icon-trash" />
      </DeleteButton>
    </FilterAndActionButtonsContainer>
  )
}

export default FilterAndActions
