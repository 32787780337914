// Modules
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
// Components
import Loader from '../Loader'
import ActionButton from '../ActionButton'
import HeaderTitle from '../HeaderTitle/HeaderTitle'
// Configs
import { config } from '../../config'
// Assets
import OrderBadgesImg from '../../assets/image/wip.png'
import IconPlus from '../../assets/image/icon-plus.svg'

const OrderBadges = () => {
  const { t, i18n } = useTranslation()
  const [authLoader, setAuthLoader] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    setAuthLoader(false)
  }, [dispatch, i18n.language])

  const handleOrderBadge = () => {
    window.open(`${config.orderBadgesPdfLink}`, '_blank')
  }

  return (
    <>
      {!authLoader ? (
        <>
          <section className="mid-section order-badges">
            <HeaderTitle title={t('page-title.main-badge')} />

            <div className="block-container text-center">
              <div>
                <img src={OrderBadgesImg} className="img-fluid order-badge" alt="images" />
                <h1 className="title">{t('badges.order-badges.title')}</h1>
                <p className="description">{t('badges.order-badges.description')}</p>

                <ActionButton
                  icon={IconPlus}
                  triggerFunction={handleOrderBadge}
                  text={t('badges.order-badges.buttons.orderBadges')}
                />
              </div>
            </div>
          </section>
        </>
      ) : (
        <section className="mid-section order-badges">
          <Loader />
        </section>
      )}
    </>
  )
}

export default OrderBadges
