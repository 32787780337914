// Modules
import { combineReducers } from 'redux'
// Actions
import IssuersReducer from '../../ducks/Issuers.ducks'
import BadgesReducer from '../../ducks/Badges.ducks'
import AwardsReducer from '../../ducks/Awards.ducks'
import OrdersDucks from '../../ducks/Orders.ducks'
import spinnerReducer from './spinnerReducer'

const rootReducer = combineReducers({
  issuers: IssuersReducer,
  badges: BadgesReducer,
  awards: AwardsReducer,
  orders: OrdersDucks,
  spinner: spinnerReducer
})

export default rootReducer
