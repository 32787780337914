import styled from 'styled-components'

export const OrderButton = styled.button`
  border: none;
  width: 140px;
  transition: all 0.3s ease;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  background-color: #0041d9;
  height: 48px;
  border-radius: 21px;

  &:hover {
    background-color: #002aa3;
  }
`
