// Modules
import CryptoJS from 'crypto-js'

import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { addMonths, addYears } from 'date-fns'
// Actions
import { generateApiKey, deleteApiKey } from '../../../ducks/Auth.ducks'
//Helpers
import { generateApiKeyHelper } from '../helpers/apiKeyHelper'

export const useApiKey = (auth, setIsFirstKeyVisible) => {
  const dispatch = useDispatch()
  const { apiAccessDetails } = auth || {}

  const [apiKey, setApiKey] = useState(apiAccessDetails?.key || null)
  const [copySuccess, setCopySuccess] = useState(false)
  const [expiration, setExpiration] = useState({
    value: apiAccessDetails?.expiresAt ? 'date' : 'never',
    date: new Date(apiAccessDetails?.expiresAt) || null
  })

  const generateNewApiKey = useCallback(async () => {
    const apiKey = generateApiKeyHelper(auth.id)

    generateApiKey &&
      generateApiKey({
        userId: auth?.id,
        data: {
          apiAccessDetails: {
            key: apiKey,
            expiresAt: expiration
          }
        },
        dispatch,
        successCb: () => {
          setApiKey(apiKey)
          setIsFirstKeyVisible(true)
        }
      })
  }, [auth?.id, expiration])

  const handleCopyApiKey = useCallback(() => {
    navigator.clipboard.writeText(apiKey)
    setCopySuccess(true)
    setTimeout(() => setCopySuccess(false), 2000)
  }, [apiKey])

  const handleDeleteApiKey = useCallback(() => {
    deleteApiKey &&
      deleteApiKey({
        userId: auth?.id,
        dispatch,
        successCb: () => setApiKey(null)
      })
  }, [auth?.id])

  const handleExpirationChange = (e) => {
    const value = e.target.value
    let date = null
    switch (value) {
      case '1month':
        date = addMonths(new Date(), 1)
        break
      case '3months':
        date = addMonths(new Date(), 3)
        break
      case '6months':
        date = addMonths(new Date(), 6)
        break
      case '12months':
        date = addYears(new Date(), 1)
        break
      case 'never':
        date = null
        break
      default:
        date = addMonths(new Date(), 6)
    }
    setExpiration({ value, date })
  }

  return {
    apiKey,
    copySuccess,
    expiration,
    generateNewApiKey,
    handleCopyApiKey,
    handleDeleteApiKey,
    handleExpirationChange
  }
}
