// Modules
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// Styled Components
import {
  CloseButton,
  FilterContainer,
  FilterSection,
  Header,
  SectionLabel,
  SelectDropdown,
  Title
} from './FilterPopupStyled'
// Icons
import { GrClose } from 'react-icons/gr'

export default function FilterPopup({ isOpen, options, selected, onChange, onClose }) {
  const { t } = useTranslation()
  useEffect(() => {}, [isOpen])

  return (
    <FilterContainer $isOpen={isOpen}>
      <Header>
        <Title>Filter</Title>
        <CloseButton onClick={onClose}>
          <GrClose size="18" />
        </CloseButton>
      </Header>

      <FilterSection>
        <SectionLabel>{t('badge-card.search-module.filter-data-text')}</SectionLabel>
        <SelectDropdown
          isMulti
          options={options}
          isSearchable={true}
          isClearable={true}
          onChange={onChange}
          className="react-select height-set"
          classNamePrefix="select"
          placeholder={t('badge-card.search-module.filter-placeholder-text')}
          value={selected}
        />
      </FilterSection>
    </FilterContainer>
  )
}
