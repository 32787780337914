// Modules
import axios from 'axios'
import get from 'lodash.get'
// Utils
import { decryptSession } from './session'

export const SESSION = 'session'

async function performRequest(action) {
  const { method, path, data, params } = action

  const { authToken } = decryptSession(SESSION, true) || {}

  const options = {
    mode: 'cors',
    headers: {
      'Content-Type': get(action, 'headers.contentType', undefined) || 'application/json',
      Authorization: `Bearer ${authToken}`
    }
  }

  return await axios({
    method,
    // eslint-disable-next-line no-use-before-define
    baseURL: api.url(),
    url: path,
    headers: options.headers,
    params: params ? params : {},
    data
  })
}

const api = {
  delete: (path, data) => {
    return performRequest({ method: 'delete', path, data })
  },
  get: (path, params) => {
    return performRequest({ method: 'get', path, params })
  },
  post: (path, data) => {
    return performRequest({ method: 'post', path, data })
  },
  postFile: ({ path, data }) => {
    return performRequest({
      method: 'post',
      path,
      data,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  put: (path, data) => {
    return performRequest({ method: 'put', path, data })
  },
  url: () => {
    return process.env.REACT_APP_API_BASE_URL
  }
}

export default api
