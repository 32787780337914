// Mdoules
import { useTranslation } from 'react-i18next'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
// Helpers
import { formatDate } from '../../../helpers/dates'
// Styled Components
import {
  AwardTable,
  BackButton,
  FooterContainer,
  ModalTitle,
  NextButton
} from '../AwardOrderApproval/AwardOrderApprovalStyled'

const ConfirmationStep = ({ order, handleBack, handleComplete }) => {
  const { t } = useTranslation()

  return (
    <>
      <ModalHeader>
        <ModalTitle>{t('awardOrders.confirmation.title')}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <AwardTable>
          <thead>
            <tr>
              <th>{t('awardOrders.confirmation.table.name')}</th>
              <th>{t('awardOrders.confirmation.table.email')}</th>
              <th>{t('awardOrders.confirmation.table.award-date')}</th>
              <th>{t('awardOrders.confirmation.table.expiration-date')}</th>
            </tr>
          </thead>
          <tbody>
            {order?.awards?.map((award, i) => (
              <tr key={i}>
                <td>{`${award?.firstName} ${award?.lastName}`}</td>
                <td>{award?.email}</td>
                <td>
                  {formatDate(award?.issueDate, 'de-DE', {
                    month: 'long',
                    day: '2-digit',
                    year: 'numeric'
                  })}
                </td>
                <td>
                  {formatDate(award?.expiryDate, 'de-DE', {
                    month: 'long',
                    day: '2-digit',
                    year: 'numeric'
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </AwardTable>
      </ModalBody>
      <ModalFooter>
        <FooterContainer>
          <BackButton onClick={handleBack}>
            ← {t('awardOrders.confirmation.buttons.back')}
          </BackButton>
          <NextButton onClick={handleComplete} isConfirmButton>
            {t('awardOrders.confirmation.buttons.issue')}
          </NextButton>
        </FooterContainer>
      </ModalFooter>
    </>
  )
}
export default ConfirmationStep
