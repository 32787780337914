import CryptoJS from 'crypto-js'

/**
 * Generates a secure API key by appending an encrypted user ID to a UUID
 * @param {string} userId - The user ID to be encrypted
 * @returns {string} - The formatted API key
 */
export const generateApiKeyHelper = (userId) => {
  if (!userId) {
    throw new Error('User ID is required to generate an API key')
  }

  const secretKey = process.env.REACT_APP_JWT_SECRET

  // Encrypt User ID
  const encryptedUserId = CryptoJS.AES.encrypt(userId, secretKey).toString()

  // Generate UUID and append the formatted encrypted user ID
  const uuid = crypto.randomUUID()

  // Generate UUID and append encrypted user ID
  return `${uuid}-${encryptedUserId}`
}
