// Modules
import styled from 'styled-components'
import Select from 'react-select'
// Colors
import colors from '../../helpers/colors'

export const FilterContainer = styled.div`
  position: absolute;
  background: white;
  width: 320px;
  /* top: 15%;  */
  right: 0;
  border-radius: 8px;
  z-index: 1000;
  transition: 0.3s ease-in-out;
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  visibility: ${(props) => (props.$isOpen ? 'visible' : 'hidden')};
  transform: translateY(${(props) => (props.$isOpen ? '0' : '-10px')});
  pointer-events: ${(props) => (props.$isOpen ? 'all' : 'none')};
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #eee;
`

export const Title = styled.h2`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: ${colors.darkSlateBlue};
`

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #333;
  }

  svg path {
    stroke: ${colors.darkSlateBlue};
  }
`

export const FilterSection = styled.div`
  padding: 20px;
`

export const SectionLabel = styled.div`
  font-size: 10px;
  color: ${colors.blueyGrey};
  margin-bottom: 8px;
  font-weight: 500;
`

export const SelectDropdown = styled(Select)``
