// Modules
import styled from 'styled-components'
import { Modal, Button, Input, Label, Table } from 'reactstrap'
// Colors
import colors from '../../../helpers/colors'

export const StyledModal = styled(Modal)`
  max-width: 1000px;
  .modal-content {
    border-radius: 8px;
  }
`

export const ModalTitle = styled.div`
  font-size: 18px;
  color: ${colors.darkerSlateBlue};
`

export const OrderTable = styled.div`
  overflow: hidden;
  margin-bottom: 20px;
`

export const TableHeaderRow = styled.div`
  display: flex;
  padding: 12px 15px;
`

export const TableDataRow = styled.div`
  display: flex;
  padding: 15px;
  background: ${colors.digitalBlueT4};
  border-radius: 10px;
`

export const TableCell = styled.div`
  flex: 1;
  width: 'auto';
`

export const TableHeader = styled.div`
  color: ${colors.blueyGrey};
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 500;
`

export const TableData = styled.div`
  color: ${colors.darkerSlateBlue};
  font-size: 14px;
  margin-top: 4px;
`
// Award Table
export const AwardTable = styled(Table)`
  width: 100%;
  border-collapse: collapse;

  /* Header Styles */

  thead {
    th {
      color: ${colors.blueyGrey};
      font-weight: 500;
      font-size: 10px;
      text-transform: uppercase;
      text-align: left;
      letter-spacing: 0.5px;
      border: none;
    }
  }

  /* Body Styles */
  tbody {
    display: block;
    max-height: 400px;
    overflow-y: auto;
    border: none !important;

    tr {
      border-radius: 5px;

      td {
        color: ${colors.darkerSlateBlue};
        font-size: 13px;
        border: none;
      }
    }
    /* Webkit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
      width: 6px; /* Thin scrollbar */
      border-radius: 10px; /* Rounded scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: transparent; /* Hide track */
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px; /* Fully rounded scrollbar */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    /* Remove top & bottom arrows */
    &::-webkit-scrollbar-button:vertical:start:increment,
    &::-webkit-scrollbar-button:vertical:start:decrement,
    &::-webkit-scrollbar-button:vertical:end:increment,
    &::-webkit-scrollbar-button:vertical:end:decrement {
      display: none;
      height: 0;
    }

    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: #888 transparent; /* Hide track */
  }

  /* Table Cells */

  /* Row Background Color */
  tr:nth-child(even) {
    background: ${colors.digitalBlueT4};
  }

  /* Table Layout */
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    border: none !important;
  }
`

export const SearchContainer = styled.div`
  position: relative;
  margin: 20px 0;
  max-width: 300px;
`

export const SearchInput = styled(Input)`
  border-radius: 25px;
  padding-right: 40px;
  &:focus {
    box-shadow: none;
    border-color: #80bdff;
  }
`

export const SearchIcon = styled.div`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
`

export const TemplateList = styled.ul`
  list-style: none;
  padding: 0;
  max-height: 250px;
  overflow-y: auto;
  border-radius: 4px;

  /* Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 6px; /* Thin scrollbar */
    border-radius: 10px; /* Rounded scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* Hide track */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px; /* Fully rounded scrollbar */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Remove top & bottom arrows */
  &::-webkit-scrollbar-button:vertical:start:increment,
  &::-webkit-scrollbar-button:vertical:start:decrement,
  &::-webkit-scrollbar-button:vertical:end:increment,
  &::-webkit-scrollbar-button:vertical:end:decrement {
    display: none;
    height: 0;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 transparent; /* Hide track */
`

// Add a new style for the template list container
export const TemplateListContainer = styled.div`
  margin-top: 1rem;
`

export const TemplateItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .form-check {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input {
    appearance: auto;
    margin: 0 auto !important;
    cursor: pointer;
    min-height: auto !important;
  }
  label {
    color: ${colors.darkerSlateBlue};
    cursor: pointer;
  }
`

export const RadioLabel = styled(Label)`
  display: flex;
  margin-left: 10px;
  margin-bottom: 0;
  flex: 1;
  align-items: center;
  gap: 10px;
  min-height: 40px;
  line-height: normal !important;
`

export const SuggestedTag = styled.span`
  background-color: rgba(95, 153, 255, 0.1);
  color: ${colors.digitalBlue};
  font-size: 10px;
  padding: 5px;
  border-radius: 4px;
  margin-left: 10px;
  border: 1px solid ${colors.digitalBlue};
`

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const BackButton = styled(Button)`
  color: ${colors.darkerSlateBlue};
  background: transparent;
  border: none;
  font-size: 13px;
  text-transform: uppercase;
  &:hover {
    color: ${colors.darkerSlateBlue};
    background: transparent;
    opacity: 0.8;
  }
`

export const NextButton = styled.button`
  color: ${colors.white};
  background-color: ${(props) => (props.isConfirmButton ? colors.oliveGreen : colors.blue)};
  border: none;
  padding: 10px 40px;
  border-radius: unset;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  text-transform: uppercase;
  font-size: 13px;
  &:disabled {
    background-color: ${colors.darkPaleGrey};
    opacity: 0.9;
  }
  &:not(:disabled) {
    background-color: ${(props) => (props.isConfirmButton ? colors.oliveGreen : colors.blue)};

    &:hover {
      opacity: 0.9;
    }
  }
`

export const ConfirmationContainer = styled.div`
  text-align: center;
  padding: 20px;
`

export const ConfirmationTitle = styled.h3`
  margin-bottom: 20px;
`

export const ConfirmationDetails = styled.div`
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  text-align: left;
`
