// Modules
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
// Actions
import { issueAwardsForBadge } from '../../../ducks/Orders.ducks'
// Styled Components
import { StyledModal } from './AwardOrderApprovalStyled'
import ConfirmationStep from '../components/ConfirmationStep'
import TemplateSelectionStep from '../components/TemplateSelectionStep'
import AwardsSummary from '../components/AwardsSummary'
// Icons
import { FaCheck, FaExclamation } from 'react-icons/fa'

const AwardOrderApproval = ({ isOpen, toggle, order, getOrders }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [step, setStep] = useState(1)
  const [selectedTemplate, setSelectedTemplate] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [awardsSummary, setAwardsSummary] = useState({
    open: false,
    duplicateEntries: []
  })

  useEffect(() => {
    const preselectedBadge = order?.issuer?.badges?.find(
      (badge) => badge.uuid === order?.badgeTemplateId
    )

    if (preselectedBadge) {
      setSelectedTemplate(preselectedBadge)
    }
  }, [order])

  const handleSearchQueryChange = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setSearchQuery(e.target.value)
  }, [])

  const filteredBadgeTemplates =
    order?.issuer?.badges?.filter((badge) =>
      badge.name.toLowerCase().includes(searchQuery.toLowerCase())
    ) || []

  const handleModalClose = () => {
    toggle()
    setStep(1)
    setSelectedTemplate('')
    setSearchQuery('')
  }
  const handleNext = () => step === 1 && selectedTemplate && setStep(2)
  const handleBack = () => (step === 1 ? handleModalClose() : setStep(1))

  const handleComplete = () => {
    issueAwardsForBadge &&
      issueAwardsForBadge({
        orderId: order?.id,
        badgeId: selectedTemplate?.id,
        awards: order?.awards,
        dispatch,
        successCb: ({ duplicates }) => {
          getOrders()
          handleModalClose()
          setAwardsSummary({ open: true, duplicateEntries: duplicates })
          toast.success(t('awardOrders.listing.messages.delete-orders-successfully'), {
            icon: <FaCheck />
          })
        },
        errorCb: () => {
          setAwardsSummary({ open: false, duplicateEntries: [] })
          toast.error(t('awardOrders.listing.messages.delete-orders-error'), {
            icon: <FaExclamation />
          })
        }
      })
  }

  return (
    <>
      <StyledModal isOpen={isOpen} size="lg">
        {step === 1 ? (
          <TemplateSelectionStep
            orderDetails={order}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            filteredBadges={filteredBadgeTemplates}
            searchQuery={searchQuery}
            handleSearchQueryChange={handleSearchQueryChange}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        ) : (
          <ConfirmationStep order={order} handleBack={handleBack} handleComplete={handleComplete} />
        )}
      </StyledModal>
      <AwardsSummary
        isOpen={awardsSummary.open}
        totalAwards={order?.awards}
        duplicateEntries={awardsSummary.duplicateEntries}
        onConfirm={() => {
          setAwardsSummary((prev) => ({
            ...prev,
            open: !prev.open
          }))
        }}
      />
    </>
  )
}

export default AwardOrderApproval
