// Modules
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'
import get from 'lodash.get'
// Actions
import { deleteIssuer } from '../../../ducks/Issuers.ducks'
import { transferBulkBadges } from '../../../ducks/Badges.ducks'
// Utils
import { config } from '../../../config'
import { hasPermission } from '../../../helpers/permissions'
import { routerPath, PATH, ACTION } from '../../../constants/constants'
// Components
import {
  Popup,
  ActionButton,
  SearchBox,
  PaginationInfiniteScroll,
  PaginationLoad,
  EmptyData
} from '../../../components'
import CardListing from './components/CardListing'
import ListListing from './components/ListListing'
import BulkBadgeTransfer from './components/BulkBadgeTransfer'
// Assets
import { BiGridAlt, BiListUl } from 'react-icons/bi'
import IconToastError from '../../../assets/image/icon-toast-error.png'
import IconToastSuccess from '../../../assets/image/icon-toast-success.svg'
import IconBack from '../../../assets/image/icon-left.svg'
import IconPlus from '../../../assets/image/icon-plus.svg'
import IconPen from '../../../assets/image/icon-pen.svg'
import IconDelete from '../../../assets/image/icon-delete.svg'

export const BADGES_PER_PAGE = 16
export const DELAY = 1000

const BadgesListing = ({
  t,
  auth,
  currentIssuer,
  availableIssuers,
  badges,
  bulkSelection,
  setBulkSelection,
  isDataEmpty
}) => {
  const location = useLocation()
  const history = useHistory()
  const { id: issuerId, name: issuerName } = location?.state || {}
  const { badgeCount, awardsCount, receivable_number, payable_number } = currentIssuer || {}

  const [listBadges, setListBadges] = useState([])
  const [showComponent, setShowComponent] = useState(false)
  const [isGridView, setIsGridView] = useState(true)

  const [state, setState] = useState({
    isActive: false,
    isFocus: false,
    hasMore: true,
    searchQuery: '',
    searchFilters: undefined,
    modalCancel: false,
    modalDelete: false
  })

  const badgesLoop = badges.slice(0, BADGES_PER_PAGE)
  const hasBadges = listBadges && listBadges.length > 0

  useEffect(() => {
    setListBadges(badgesLoop)
    const timeoutId = setTimeout(() => {
      setShowComponent(true)
    }, DELAY)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [badges, deleteIssuer])

  const toggleView = () => {
    setIsGridView(!isGridView)
  }

  const handleLoadMoreBadges = () => {
    if (listBadges.length >= badges.length) {
      setState((prevPageData) => ({
        ...prevPageData,
        hasMore: false
      }))
      return
    }
    const newBadges = badges?.slice(listBadges.length, listBadges.length + BADGES_PER_PAGE)
    return new Promise((resolve) => {
      setTimeout(() => {
        setListBadges([...listBadges, ...newBadges])
        resolve()
      }, 300)
    })
  }

  const handleSearchChange = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const { value } = event.target

    if (!value) {
      setListBadges(badges)
    }
    const filteredIssuers = badges.filter((badge) =>
      badge.name.toLowerCase().includes(value.toLowerCase())
    )
    setListBadges(filteredIssuers)
  }

  const handleSearchOnFocus = () => {
    setState((prevPageData) => ({
      ...prevPageData,
      isFocus: true
    }))
  }

  const handleDeletePopup = () => {
    setState((prevPageData) => ({
      ...prevPageData,
      modalDelete: true
    }))
  }

  const handleIssuerDelete = () => {
    deleteIssuer({
      id: issuerId,
      successCb: () => {
        history.replace(`/?token=${auth?.authToken}`)
        toast.success(t('issuers.form.messages.deleted'), {
          icon: <img src={IconToastSuccess} className="img-fluid" alt="images" />
        })
      },
      errorCb: (status) => {
        if (status === 400) {
          setState((prevPageData) => ({
            ...prevPageData,
            modalCancel: true,
            modalDelete: false
          }))
        }
        toast.error(t('issuers.form.messages.error'), {
          icon: <img src={IconToastError} className="img-fluid" alt="images" />
        })
      }
    })
  }

  const handleCreateBadge = () => {
    history.push({
      pathname: routerPath.CREATE_BADGE,
      state: {
        issuerId,
        issuerName
      }
    })
  }

  const handleEditBadge = () => {
    history.push({
      pathname: routerPath.EDIT_ISSUER,
      state: {
        issuerId,
        issuerName
      }
    })
  }

  const updateBulkSelection = ({ operation, badgeId }) => {
    let newBulkSelection = []

    if (!['unselectAll', 'selectAll'].includes(operation) && !badgeId) {
      return
    }

    if (operation === 'selectAll') {
      newBulkSelection = badges ? badges.map((badge) => get(badge, 'id', undefined)) : []
    } else if (operation === 'select') {
      newBulkSelection = [...bulkSelection, badgeId]
    } else if (operation === 'unselect') {
      newBulkSelection = bulkSelection.filter((badge) => badgeId !== badge)
    }

    setBulkSelection(newBulkSelection)
  }

  const handleGroupSelection = (groupSelection, partialSelection) => {
    if (groupSelection || partialSelection) {
      updateBulkSelection({
        operation: 'unselectAll'
      })
    } else {
      updateBulkSelection({
        operation: 'selectAll'
      })
    }
  }

  const transferBadgesToIssuer = ({ issuerId, successCb }) => {
    transferBulkBadges({
      data: { issuerId, selectedBadges: bulkSelection },
      successCb: () => {
        history.replace(`/?token=${auth?.authToken}`)
        toast.success(t('badges.listing.messages.successful-transfer'), {
          icon: <img src={IconToastSuccess} className="img-fluid" alt="images" />
        })
        successCb && successCb()
      },
      errorCb: () => {
        toast.error(t('badges.listing.messages.error-transfer'), {
          icon: <img src={IconToastError} className="img-fluid" alt="images" />
        })
      }
    })
  }

  const handleOrderBadge = () => {
    window.open(`${config.orderBadgesPdfLink}`, '_blank')
  }

  const ListingComponent = isGridView ? CardListing : ListListing

  const BadgeData = ({ listBadges, loadMore, hasMore, isDataEmpty }) => (
    <div>
      {showComponent ? (
        hasBadges ? (
          <PaginationInfiniteScroll
            dataLength={listBadges.length}
            loadMore={loadMore}
            hasMore={hasMore}
            scrollableTargetId="scrollData"
            classNameScroll={isGridView ? 'badges-card-data' : ''}>
            <ListingComponent
              t={t}
              history={history}
              badges={listBadges}
              bulkSelection={bulkSelection}
              handleGroupSelection={handleGroupSelection}
              toggleBulkSelection={updateBulkSelection}
            />
          </PaginationInfiniteScroll>
        ) : (
          isDataEmpty && (
            <EmptyData
              textId="badges.actions.messages.no-badges-found"
              orderBadges={{
                role: auth?.role,
                handleOrderBadge
              }}
            />
          )
        )
      ) : (
        <PaginationLoad />
      )}
    </div>
  )

  return (
    <>
      <section>
        <div className="breadcrumb-section mb-40">
          <div className="back-section">
            <div className="back-icon">
              {auth !== null && hasPermission(auth?.role, PATH.ISSUERS, ACTION.VIEW) && (
                <div className="icon">
                  <Link to={`/?token=${auth?.authToken}`}>
                    <img src={IconBack} className="img-fluid" alt="icon-back" />
                  </Link>
                </div>
              )}
              <div className="title">
                <h3>{issuerName}</h3>
              </div>
              <p className="span-10 mb-0 mt-2 text-uppercase">
                {t('issuers.details.back-description')}
              </p>
            </div>
            <div className="status-card">
              <label className="status-label">STATUS</label>
              <div className="badge-details">
                <div className="badge-item">
                  <div className="count">{badgeCount}</div>
                  <label>
                    {badgeCount === 1
                      ? t('badge-card.cards.template-text')
                      : t('badge-card.cards.templates-text')}
                  </label>
                </div>
                <div className="badge-item">
                  <div className="count">{awardsCount}</div>
                  <label>
                    {awardsCount === 1
                      ? t('badge-card.cards.award-text')
                      : t('badge-card.cards.awards-text')}
                  </label>
                </div>
              </div>
            </div>

            {hasPermission(auth?.role, PATH.BADGES, ACTION.VIEW_ACCOUNT_NUMBERS) && (
              <div className="account-numbers">
                <p>
                  <span> {t('issuers.general.receivable-number')}</span> {receivable_number}
                </p>
                <p>
                  <span> {t('issuers.general.payable-number')}</span> {payable_number}
                </p>
              </div>
            )}
          </div>

          <div className="search-action-buttons-section mt-20">
            <SearchBox
              t={t}
              isFocus={state.isFocus}
              handleSearchChange={handleSearchChange}
              onFocus={handleSearchOnFocus}
            />

            <div className="action-buttons">
              {hasPermission(auth?.role, PATH.BADGES, ACTION.BULK_MANAGE) && (
                <Button
                  color="primary"
                  disabled={!badges.length > 0}
                  onClick={() => {
                    toggleView(), setBulkSelection([])
                  }}
                  className="btn-icon btn-48 float-right list-grid-view">
                  {isGridView ? <BiListUl size="18" /> : <BiGridAlt size="18" />}
                </Button>
              )}
              {hasPermission(auth?.role, PATH.BADGES, ACTION.UPDATE) && (
                <ActionButton icon={IconPen} triggerFunction={handleEditBadge} />
              )}
              {hasPermission(auth?.role, PATH.BADGES, ACTION.CREATE) && (
                <ActionButton icon={IconPlus} triggerFunction={handleCreateBadge} />
              )}
              {hasPermission(auth?.role, PATH.BADGES, ACTION.DELETE) && (
                <ActionButton icon={IconDelete} triggerFunction={handleDeletePopup} />
              )}
              {hasPermission(auth?.role, PATH.BADGES, ACTION.ORDER_BADGE) && (
                <ActionButton
                  icon={IconPlus}
                  triggerFunction={handleOrderBadge}
                  text={t('general.buttons.order')}
                />
              )}
            </div>
          </div>
        </div>

        <BadgeData
          listBadges={listBadges}
          loadMore={handleLoadMoreBadges}
          hasMore={state.hasMore}
          isDataEmpty={isDataEmpty}
        />
      </section>

      <Popup
        show={state.modalCancel}
        changeSetShow={() =>
          setState((prevPageData) => ({
            ...prevPageData,
            modalCancel: !state.modalCancel
          }))
        }
        changeSetShowToFalse={() =>
          setState((prevPageData) => ({
            ...prevPageData,
            modalCancel: false
          }))
        }
        headingText={t('issuers.form.modal.cancel-title')}
        paragraphText={t('issuers.form.modal.cancel-paragraph')}
      />
      <BulkBadgeTransfer
        t={t}
        issuers={availableIssuers}
        bulkSelection={bulkSelection}
        display={bulkSelection && bulkSelection.length > 0}
        handleBulkTransfer={transferBadgesToIssuer}
      />

      <Popup
        show={state.modalDelete}
        onClick={handleIssuerDelete}
        changeSetShow={() =>
          setState((prevPageData) => ({
            ...prevPageData,
            modalDelete: !state.modalDelete
          }))
        }
        changeSetShowToFalse={() =>
          setState((prevPageData) => ({
            ...prevPageData,
            modalDelete: false
          }))
        }
        headingText={t('issuers.form.modal.delete-title')}
        paragraphText={t('issuers.form.modal.delete-paragraph')}
        buttonIsDisabled={true}
        buttonText={t('issuers.form.modal.delete-button')}
      />
    </>
  )
}

BadgesListing.propTypes = {
  t: PropTypes.func,
  auth: PropTypes.object,
  currentIssuer: PropTypes.object,
  availableIssuers: PropTypes.array,
  badges: PropTypes.array,
  bulkSelection: PropTypes.array,
  setBulkSelection: PropTypes.func,
  isDataEmpty: PropTypes.bool
}

export default BadgesListing
