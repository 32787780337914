// Components
import { IssuersListing, UpsertIssuer } from '../view/Issuers'
import { BadgesListing, BadgeDetails, UpsertBadge } from '../view/Badges'
import { RecipientsListing, RecipientDetails, AwardsForm } from '../view/BadgeAwards'
import { AwardOrdersListing } from '../view/AwardOrders'
import { OrderBadges, RouteNotFound, UserNotFound } from '../components'
// Constants
import { routerPath } from '../constants/constants'

const routes = [
  {
    path: '/',
    name: 'issuerMain ',
    component: IssuersListing
  },
  {
    path: routerPath.ISSUER_DETAILS,
    name: 'issuerDetails ',
    component: BadgesListing
  },
  {
    path: routerPath.CREATE_ISSUER,
    name: 'createIssuer ',
    component: UpsertIssuer
  },
  {
    path: routerPath.EDIT_ISSUER,
    name: 'editIssuer ',
    component: UpsertIssuer
  },
  {
    path: routerPath.BADGE_DETAILS,
    name: 'badgeDetails ',
    component: BadgeDetails
  },
  {
    path: routerPath.CREATE_BADGE,
    name: 'addBadge ',
    component: UpsertBadge
  },
  {
    path: routerPath.BADGE_RECIPIENT,
    name: 'badgeRecipient ',
    component: RecipientsListing
  },
  {
    path: routerPath.EDIT_BADGE,
    name: 'editBadge ',
    component: UpsertBadge
  },
  {
    path: routerPath.RECIPIENT_DETAILS,
    name: 'recipientDetails ',
    component: RecipientDetails
  },
  {
    path: routerPath.AWARD_BADGE,
    name: 'awardBadge ',
    component: AwardsForm
  },
  {
    path: routerPath.USER_NOT_FOUND,
    name: 'check',
    component: UserNotFound
  },
  {
    path: routerPath.ORDER_BADGES,
    name: 'orderBadges',
    component: OrderBadges
  },
  {
    path: routerPath.AWARD_ORDERS,
    name: 'awardOrdersListing',
    component: AwardOrdersListing
  },
  {
    path: '*',
    name: 'unavailabeleRoutes',
    component: RouteNotFound
  }
]

export default routes
