// Modules
import { useTranslation } from 'react-i18next'
import { FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
// Helpers
import { formatDate } from '../../../helpers/dates'
// Styled Components
import {
  TableHeader,
  ModalTitle,
  OrderTable,
  TableCell,
  TableHeaderRow,
  TableDataRow,
  TableData,
  SearchContainer,
  SearchInput,
  TemplateListContainer,
  TemplateList,
  TemplateItem,
  RadioLabel,
  SearchIcon,
  FooterContainer,
  BackButton,
  NextButton,
  SuggestedTag
} from '../AwardOrderApproval/AwardOrderApprovalStyled'
// Icons
import { IoIosSearch } from 'react-icons/io'

const TemplateSelectionStep = ({
  selectedTemplate,
  setSelectedTemplate,
  orderDetails,
  filteredBadges,
  searchQuery,
  handleSearchQueryChange,
  handleBack,
  handleNext
}) => {
  const { t } = useTranslation()

  return (
    <>
      <ModalHeader>
        <ModalTitle>{t('awardOrders.template-selection.title')}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <OrderTable>
          <TableHeaderRow>
            <TableCell>
              <TableHeader>{t('awardOrders.template-selection.table.course-title')}</TableHeader>
            </TableCell>
            <TableCell>
              <TableHeader>
                {t('awardOrders.template-selection.table.course-start-date')}
              </TableHeader>
            </TableCell>
            <TableCell>
              <TableHeader>{t('awardOrders.template-selection.table.course-end-date')}</TableHeader>
            </TableCell>
            <TableCell>
              <TableHeader>{t('awardOrders.template-selection.table.issuer')}</TableHeader>
            </TableCell>
          </TableHeaderRow>
          <TableDataRow>
            <TableCell>
              <TableData>{orderDetails?.courseTitle}</TableData>
            </TableCell>
            <TableCell>
              <TableData>
                {formatDate(orderDetails?.courseStart, 'de-DE', {
                  month: 'long',
                  day: '2-digit',
                  year: 'numeric'
                })}
              </TableData>
            </TableCell>
            <TableCell>
              <TableData>
                {formatDate(orderDetails?.courseEnd, 'de-DE', {
                  month: 'long',
                  day: '2-digit',
                  year: 'numeric'
                })}
              </TableData>
            </TableCell>
            <TableCell>
              <TableData>{orderDetails?.issuer?.name}</TableData>
            </TableCell>
          </TableDataRow>
        </OrderTable>

        <div>
          <ModalTitle>{t('awardOrders.template-selection.choose-tempalte')}</ModalTitle>
          <SearchContainer>
            <SearchInput
              type="text"
              placeholder={t('awardOrders.general.search')}
              value={searchQuery}
              onChange={handleSearchQueryChange}
            />

            <SearchIcon>
              <IoIosSearch />
            </SearchIcon>
          </SearchContainer>

          <TemplateListContainer>
            <Label>{t('awardOrders.template-selection.template-title')}</Label>

            <TemplateList>
              {filteredBadges.map((badge) => {
                const isChecked = selectedTemplate.id === badge.id
                const isSuggested = orderDetails.badgeTemplateId === badge.uuid

                const { id, name } = badge

                return (
                  <TemplateItem key={id}>
                    <FormGroup check>
                      <RadioLabel check htmlFor={id}>
                        <Input
                          type="radio"
                          id={id}
                          name="selectBadgeTemplate"
                          checked={isChecked}
                          onChange={() => setSelectedTemplate(badge)}
                        />
                        {name}
                        {isSuggested && (
                          <SuggestedTag>
                            {t('awardOrders.template-selection.selected-by-issuer')}
                          </SuggestedTag>
                        )}
                      </RadioLabel>
                    </FormGroup>
                  </TemplateItem>
                )
              })}
            </TemplateList>
          </TemplateListContainer>
        </div>
      </ModalBody>
      <ModalFooter>
        <FooterContainer>
          <BackButton onClick={handleBack}>
            ← {t('awardOrders.template-selection.buttons.back')}
          </BackButton>
          <NextButton disabled={!selectedTemplate} onClick={handleNext}>
            {t('awardOrders.template-selection.buttons.next')}
          </NextButton>
        </FooterContainer>
      </ModalFooter>
    </>
  )
}

export default TemplateSelectionStep
