// Modules
import { isPast } from 'date-fns'
// Styles
import {
  TokenDisplay,
  TokenInput,
  TokenActions,
  ExpirationStatus,
  InfoMessage,
  ActionButton,
  CopyIcon,
  DeleteIcon
} from '../../ApiKeyModalStyled'

const ApiKeyView = ({
  apiKey,
  isFirstKeyVisible,
  copySuccess,
  expiration,
  handleCopyApiKey,
  handleDeleteApiKey,
  getExpirationText,
  t
}) => {
  return (
    <>
      <TokenDisplay>
        <TokenInput inputType={isFirstKeyVisible ? 'text' : 'password'} value={apiKey} readOnly />
        <TokenActions>
          {isFirstKeyVisible && (
            <ActionButton
              onClick={handleCopyApiKey}
              success={copySuccess}
              title={t('apiKeys.modal.buttons.copy')}>
              <CopyIcon />
            </ActionButton>
          )}

          <ActionButton
            onClick={handleDeleteApiKey}
            danger
            title={t('apiKeys.modal.buttons.delete')}>
            <DeleteIcon />
          </ActionButton>
        </TokenActions>
      </TokenDisplay>

      <ExpirationStatus
        expired={expiration.value !== 'never' && expiration.date && isPast(expiration.date)}>
        {t('apiKeys.modal.expiration.expires-in')}:<strong>{getExpirationText()}</strong>
      </ExpirationStatus>

      <InfoMessage>
        {isFirstKeyVisible
          ? t('apiKeys.modal.copy-apikey-note')
          : expiration.value === 'never'
          ? t('apiKeys.modal.expiration.non-expiring-key')
          : isPast(expiration.date)
          ? t('apiKeys.modal.expiration.expired-key')
          : t('apiKeys.modal.expiration.expiring-key')}
      </InfoMessage>
    </>
  )
}

export default ApiKeyView
