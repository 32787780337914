const colors = {
  bgGrey: '#F7F8FC',
  black: '#000000',
  blue: '#0041D9',
  blueyGrey: '#8B95B1',
  darkPaleGrey: '#C1C7D7',
  darkSlateBlue: '#23366C',
  darkerSlateBlue: '#23366C',
  digitalBlue: '#5F99FF',
  digitalBlueT0: '#5785D6',
  digitalBlueT4: '#E6EDF9',
  green: '#77C99C',
  intergalacticPurple: '#7F5DFF',
  leo: '#FFC55D',
  lightGreen: '#D2EEB5',
  lightLeo: '#F8E5C5',
  lighterSlateBlue: '#F4F5F8',
  lightPaleGrey: '#F1F2F7',
  melon: '#FF7959',
  oldMint: '#77C99C',
  oliveGreen: '#8CBF59',
  paleGreen: '#E8F2DE',
  paleGrey: '#E8EAF0',
  paleOrange: '#FFE8D8',
  palePetrol: '#13698D',
  paleYellow: '#FFF9E1',
  peppermint: '#56A1A4',
  pinkology: '#FF5D81',
  summerHeat: '#FF905D',
  toastedYellow: '#FFD946',
  white: '#FFFFFF'
}

export default colors
