import styled, { keyframes } from 'styled-components'
// Colors
import colors from '../../../../helpers/colors'
// Icons
import { FaChevronCircleDown } from 'react-icons/fa'

// Animations
const fadeIn = keyframes`
  from { opacity: 0; transform: scale(0.95); }
  to { opacity: 1; transform: scale(1); }
`

// Animations

const fadeInUp = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`

const pulse = keyframes`
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
`

// Styled Components
export const Overlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  z-index: 99;
`

export const PopupContainer = styled.div`
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 28rem;
  width: 100%;
  animation: ${fadeIn} 0.3s ease-out;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
`

export const Title = styled.h2`
  font-size: 1.125rem;
  font-weight: 500;
  color: ${colors.darkerSlateBlue};
`

export const Content = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const StatRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StatLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const StatDot = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 9999px;
  background-color: ${colors.blue};
`

export const StatText = styled.span`
  font-size: 0.875rem;
  color: ${colors.darkerSlateBlue};
`

export const StatValue = styled.span`
  font-weight: 600;
`

export const SuccessCard = styled.div`
  background: ${(props) =>
    (props.percentage === 100
      ? 'linear-gradient(to right, #23366c, #415a9e)'
      : 'linear-gradient(to right, #e74c3c, #c0392b)')};
  color: white;
  padding: 0.75rem;
  border-radius: 0.5rem;
`
export const SuccessRate = styled.p`
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 600;
  margin-top: 0.5rem;
  margin-bottom: 0;
`

export const SuccessText = styled.p`
  font-size: 0.75rem;
  margin-top: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
`
export const ProgressBarBg = styled.div`
  margin-top: 0.25rem;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 9999px;
  height: 0.375rem;
`

export const ProgressBar = styled.div`
  background-color: white;
  height: 0.375rem;
  border-radius: 9999px;
  width: ${(props) => props.width || '0%'};
`
/*
 * Dublicates styled components
 */

export const DuplicatesSection = styled.div`
  margin-top: 1rem;
`

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
`

export const SectionTitle = styled.h3`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${colors.darkerSlateBlue};
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:before {
    content: '';
    display: block;
    width: 3px;
    height: 16px;
    background: linear-gradient(to bottom, #3b82f6, #8b5cf6);
    border-radius: 4px;
  }
`

export const EntryCount = styled.span`
  background-color: #eff6ff;
  color: #3b82f6;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
`

// Replace the table with a card-based layout
export const EntriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 10rem;
  overflow-y: auto;
  position: relative;
  padding-right: 0.25rem;

  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f5f9;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #cbd5e1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #94a3b8;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #cbd5e1 #f1f5f9;
`

export const EntryCard = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  padding: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #edf2f7;
  transition: all 0.2s ease;
  animation: ${fadeInUp} 0.3s ease-out;
  animation-fill-mode: both;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  /* Staggered animation delay for cards */
  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.05s;
  }
  &:nth-child(3) {
    animation-delay: 0.1s;
  }
  &:nth-child(4) {
    animation-delay: 0.15s;
  }
  &:nth-child(5) {
    animation-delay: 0.2s;
  }
`

export const EntryInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`

export const EntryDetails = styled.div`
  display: flex;
  flex-direction: column;
`

export const EntryName = styled.div`
  font-weight: 500;
  color: #1f2937;
`

export const EntryEmail = styled.div`
  font-size: 0.875rem;
  color: #6b7280;
`

export const DuplicateTag = styled.div`
  background-color: #fee2e2;
  color: #ef4444;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;

  &:before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #ef4444;
  }
`

export const ScrollIndicator = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2rem;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 0.5rem;
  pointer-events: none;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.2s ease;
`

export const ScrollChevron = styled(FaChevronCircleDown)`
  color: #6b7280;
  width: 1.25rem;
  height: 1.25rem;
  animation: ${pulse} 2s infinite ease-in-out;
`

export const DuplicateDescription = styled.p`
  font-size: 13px;
  margin-top: 20px;
  color: ${colors.darkerSlateBlue};
`

export const Footer = styled.div`
  padding: 1rem;
  background-color: #f9fafb;
  display: flex;
  justify-content: center;
`

export const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${colors.blue};
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    opacity: 0.9;
  }
`
