import {
  FormGroup,
  Label,
  SelectWrapper,
  Select,
  ActionButton,
  ChevronIcon,
  GenerateIcon,
  GenerationScreen
} from '../../ApiKeyModalStyled'

const ApiKeyGeneration = ({ expiration, handleExpirationChange, generateNewApiKey, t }) => {
  return (
    <GenerationScreen>
      <h4>{t('apiKeys.modal.subtitle')}</h4>
      <p>{t('apiKeys.modal.description')}</p>
      <FormGroup>
        <Label htmlFor="expiration">{t('apiKeys.modal.expiration.label')}</Label>
        <SelectWrapper>
          <Select id="expiration" value={expiration.value} onChange={handleExpirationChange}>
            {['1month', '3months', '6months', '12months', 'never'].map((option) => (
              <option key={option} value={option}>
                {t(`apiKeys.modal.expiration.options.${option}`)}
              </option>
            ))}
          </Select>
          <ChevronIcon />
        </SelectWrapper>
      </FormGroup>
      <ActionButton onClick={generateNewApiKey} primary fullWidth>
        <GenerateIcon /> {t('apiKeys.modal.buttons.generate')}
      </ActionButton>
    </GenerationScreen>
  )
}

export default ApiKeyGeneration
