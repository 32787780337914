// Modules
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { differenceInDays, isPast } from 'date-fns'
import PropTypes from 'prop-types'
import { Modal, ModalBody } from 'reactstrap'
// Hooks
import { useApiKey } from './hooks/useApiKey'
// Components
import ApiKeyView from './components/ApiKeyView/ApiKeyView'
import ApiKeyGeneration from './components/ApiKeyGeneration/ApiKeyGeneration'

const ApiKeyModal = ({ isOpen, auth, changeSetShowToFalse }) => {
  const { t } = useTranslation()
  const { apiAccessDetails } = auth || {}
  const [isFirstKeyVisible, setIsFirstKeyVisible] = useState(!apiAccessDetails?.key)
  const {
    apiKey,
    copySuccess,
    expiration,
    generateNewApiKey,
    handleCopyApiKey,
    handleDeleteApiKey,
    handleExpirationChange
  } = useApiKey(auth, setIsFirstKeyVisible)

  useEffect(() => {
    if (!isOpen) {
      setIsFirstKeyVisible(false)
    }
  }, [isOpen])

  const getExpirationText = () => {
    if (!expiration.date) return ''

    if (expiration.value === 'never') return `${t('apiKeys.modal.expiration.options.never')}`
    if (isPast(expiration.date)) return `${t('apiKeys.modal.expiration.options.expired')}`

    const daysRemaining = differenceInDays(expiration.date, new Date())
    return daysRemaining === 1
      ? `1 ${t('apiKeys.general.day')}`
      : `${daysRemaining} ${t('apiKeys.general.days')}`
  }

  return (
    <Modal isOpen={isOpen} className="modal-dialog-centered common-modal" backdropClassName="op-35">
      <ModalBody style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        <div className="close-modal pointer" onClick={changeSetShowToFalse}>
          <div className="icon-close" />
        </div>

        <div className="title">
          <h3 className="modal-title text-center">{t('apiKeys.modal.title')}</h3>
        </div>

        {apiKey ? (
          <ApiKeyView
            apiKey={apiKey}
            isFirstKeyVisible={isFirstKeyVisible}
            copySuccess={copySuccess}
            expiration={expiration}
            handleCopyApiKey={handleCopyApiKey}
            handleDeleteApiKey={handleDeleteApiKey}
            getExpirationText={getExpirationText}
            t={t}
          />
        ) : (
          <ApiKeyGeneration
            expiration={expiration}
            handleExpirationChange={handleExpirationChange}
            generateNewApiKey={generateNewApiKey}
            t={t}
          />
        )}
      </ModalBody>
    </Modal>
  )
}

ApiKeyModal.propTypes = {
  isOpen: PropTypes.bool,
  auth: PropTypes.object,
  changeSetShowToFalse: PropTypes.func
}

export default ApiKeyModal
