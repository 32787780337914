// Modules
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
// Stlyed Components
import {
  Button,
  Content,
  DuplicateDescription,
  DuplicatesSection,
  DuplicateTag,
  EntriesContainer,
  EntryCard,
  EntryCount,
  EntryDetails,
  EntryEmail,
  EntryInfo,
  EntryName,
  Footer,
  Header,
  Overlay,
  PopupContainer,
  ProgressBar,
  ProgressBarBg,
  ScrollChevron,
  ScrollIndicator,
  SectionHeader,
  SectionTitle,
  StatDot,
  StatLabel,
  StatRow,
  StatText,
  StatValue,
  SuccessCard,
  SuccessRate,
  SuccessText,
  Title
} from './AwardsSummaryStyled'

export default function AwardsSummary({ isOpen, totalAwards, duplicateEntries, onConfirm }) {
  const { t } = useTranslation()
  const [showScrollIndicator, setShowScrollIndicator] = useState(false)

  if (!isOpen) return null

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target
    setShowScrollIndicator(scrollTop + clientHeight < scrollHeight - 10)
  }

  const getSuccessfulAwardsCount = (totalAwards, duplicateEntries) => {
    return totalAwards.length - duplicateEntries.length
  }

  const getSuccessPercentage = (totalAwards, duplicateEntries) => {
    if (totalAwards.length === 0) return 0
    const successCount = totalAwards.length - duplicateEntries.length
    return (successCount / totalAwards.length) * 100
  }

  // Calculate success percentage
  const successPercentage = getSuccessPercentage(totalAwards, duplicateEntries)
  const successfulAwards = getSuccessfulAwardsCount(totalAwards, duplicateEntries)

  return (
    <Overlay>
      <PopupContainer>
        <Header>
          <Title>{t('awardOrders.summary.title')}</Title>
        </Header>

        <Content>
          <StatRow>
            <StatLabel>
              <StatDot />
              <StatText>{t('awardOrders.summary.total-awards')}</StatText>
            </StatLabel>
            <StatValue>{totalAwards.length}</StatValue>
          </StatRow>

          <SuccessCard percentage={successPercentage}>
            <SuccessRate>{t('awardOrders.summary.successful-rate')}</SuccessRate>
            <ProgressBarBg>
              <ProgressBar width={`${successPercentage}%`} />
            </ProgressBarBg>
            <SuccessText>
              {t(
                successfulAwards === 1
                  ? 'awardOrders.summary.successful-rate-award'
                  : 'awardOrders.summary.successful-rate-awards',
                { successfulAwards }
              )}
            </SuccessText>
          </SuccessCard>

          {duplicateEntries.length > 0 && (
            <DuplicatesSection>
              <SectionHeader>
                <SectionTitle>{t('awardOrders.summary.duplicate-entries')}</SectionTitle>
                <EntryCount>{duplicateEntries.length}</EntryCount>
              </SectionHeader>
              <EntriesContainer onScroll={handleScroll}>
                {duplicateEntries.map((entry, index) => (
                  <EntryCard key={index}>
                    <EntryInfo>
                      <EntryDetails>
                        <EntryName>
                          {entry.firstName} {entry.lastName}
                        </EntryName>
                        <EntryEmail>{entry.email}</EntryEmail>
                      </EntryDetails>
                    </EntryInfo>
                    <DuplicateTag>{t('awardOrders.summary.duplicate')}</DuplicateTag>
                  </EntryCard>
                ))}
                <ScrollIndicator visible={showScrollIndicator}>
                  <ScrollChevron />
                </ScrollIndicator>
              </EntriesContainer>
              <DuplicateDescription>
                {t('awardOrders.summary.duplicate-description')}
              </DuplicateDescription>
            </DuplicatesSection>
          )}
        </Content>

        <Footer>
          <Button onClick={onConfirm}>{t('awardOrders.summary.buttons.confirm')}</Button>
        </Footer>
      </PopupContainer>
    </Overlay>
  )
}
