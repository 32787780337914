// Modules
import { useState } from 'react'
import {
  NavbarBrand,
  NavbarToggler,
  Navbar,
  Collapse,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { withTranslation, useTranslation } from 'react-i18next'
import Select from 'react-select'
import * as qs from 'query-string'
// Action
import { useAuth } from '../../../ducks/Auth.ducks'
// Components
import { ApiKeyModal } from '../..'
// Config
import { config } from '../../../config'
import { langOptions } from '../../../constants/constants'
import { getUserRole } from '../../../helpers/userRole'
// Assets
import logo from '../../../assets/image/ihk-logo.svg'
import userManual from '../../../assets/image/icon-user-manual.svg'

const Header = () => {
  const { t, i18n } = useTranslation()
  const { authState: { auth } = {} } = useAuth()
  const { apiKeyModalOpen } = qs.parse(location.search)

  const [collapseShow, setCollapseShow] = useState(false)
  const [isApiKeyModalVisible, setIsApiKeyModalVisible] = useState(apiKeyModalOpen === 'true')

  const toggleBox = () => {
    setCollapseShow((prevState) => !prevState)
  }

  const triggerLogout = () => {
    localStorage.clear()
    window.open(`${config.redirectBaseUrl}${config.apiEndPoints.logoutRedirect}`, '_self')
  }

  return (
    <header className="header">
      <Navbar expand="md" light>
        <NavbarBrand to="/">
          <img src={logo} className="img-fluid" alt="logo" />
        </NavbarBrand>
        <NavbarToggler onClick={toggleBox} />
        <Collapse navbar className={collapseShow ? 'show' : null}>
          <Nav className="ms-auto" navbar>
            <NavItem>
              <a
                title={t('header.dropdown.manual-placeholder')}
                className="nav-link btn-link"
                href={`${config.redirectBaseUrl}${config.apiEndPoints.usermanualRedirect}`}>
                <img src={userManual} className="img-fluid" alt="logo" />
              </a>
            </NavItem>

            <NavItem className="d-none">
              <Select
                onChange={(data) => i18n.changeLanguage(data.value)}
                options={langOptions}
                defaultValue={langOptions[1]}
                isClearable={false}
                isSearchable={false}
                id="sort"
                className="react-select"
                classNamePrefix="select"
                menuPortalTarget={document.body}
              />
            </NavItem>
            {auth && auth.email && auth.role ? (
              <UncontrolledDropdown inNavbar nav>
                <DropdownToggle caret nav>
                  <div className="drop-main">
                    <div className="drop-one">
                      <div>{auth.email[0].toUpperCase()}</div>
                    </div>
                    <div className="drop-two">
                      <p>{auth.email}</p>
                      <span>{getUserRole(auth.role)}</span>
                    </div>
                  </div>
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem
                    tag="a"
                    href={`${config.redirectBaseUrl}${config.apiEndPoints.settingsRedirect}`}>
                    {t('header.dropdown.text1')}
                  </DropdownItem>
                  <DropdownItem tag="button" onClick={() => setIsApiKeyModalVisible(true)}>
                    {t('apiKeys.button-title')}
                  </DropdownItem>
                  <DropdownItem tag="button" onClick={triggerLogout}>
                    {t('header.dropdown.text2')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : (
              <UncontrolledDropdown inNavbar nav>
                <div className="drop-main">
                  <div className="drop-one"></div>
                  <div className="drop-two">
                    <p>&nbsp;</p>
                    <span>&nbsp;</span>
                  </div>
                </div>
              </UncontrolledDropdown>
            )}
          </Nav>
        </Collapse>
      </Navbar>

      <ApiKeyModal
        isOpen={isApiKeyModalVisible}
        auth={auth}
        changeSetShowToFalse={() => setIsApiKeyModalVisible((prev) => !prev)}
      />
    </header>
  )
}

export default withTranslation()(Header)
