// Modules
import { Link } from 'react-router-dom'
import styled from 'styled-components'
// Reactstrap
import { Button } from 'reactstrap'
// Colors
import colors from '../../../helpers/colors'

export const GoBack = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  width: 100%;
`

export const TopHeader = styled.div`
  display: flex;
  align-items: left;
  gap: 12px;
  padding-bottom: 8px;
`

export const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${colors.darkSlateBlue};
  text-decoration: none;
  font-size: 1.125rem;
  font-weight: 500;

  &:hover {
    color: ${colors.darkerSlateBlue};
  }

  svg {
    width: 20px;
    height: 20px;
  }
`

export const SubHeader = styled.p`
  color: ${colors.blueyGrey};
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`

export const FilterAndActionButtonsContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: end;
  width: 100%;
  padding: 15px;
  gap: 20px;
`
export const FilterButton = styled.button`
  height: 48px;
  border-radius: 8px;
  padding: 12px 19px 12px 16px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 21;
  font-weight: 400;
  color: ${colors.darkSlateBlue};
  background-color: ${colors.white};
  border: none;
  gap: 10px;
`

export const DeleteButton = styled(Button)`
  border: none;
  width: 48px;
  height: 48px;
  transition: all 0.3s ease;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  background-color: #0041d9;
  border-radius: 40px;

  &:hover {
    background-color: #002aa3;
  }
`
