// Modules
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import Select from 'react-select'
// Config
import { routerPath, PATH, ACTION } from '../../../../../constants/constants'
// Components
import { OrderButton } from './FilterBoxStyled'
// Assets
import IconPlus from '../../../../../assets/image/icon-plus.svg'
import IconFilter from '../../../../../assets/image/icon-filter.svg'
import IconClose from '../../../../../assets/image/icon-dark-close.svg'

const FilterBox = ({
  t,
  role,
  wrapperFilter,
  showFilter,
  isActive,
  hideFilter,
  options,
  onChangeOfFilter,
  hasPermission,
  searchFilters
}) => {
  const { ordersData } = useSelector((state) => state.orders)

  const AwardOrders = () => (
    <>
      {hasPermission(role, PATH.ORDERS, ACTION.VIEW) && (
        <Link title={t('awardOrders.general.orders-button')} to={routerPath.AWARD_ORDERS}>
          <OrderButton>
            {t('awardOrders.general.orders-button')} ({ordersData?.length})
          </OrderButton>
        </Link>
      )}
    </>
  )
  const AddButton = () => (
    <>
      {hasPermission(role, PATH.ISSUERS, ACTION.CREATE) && (
        <Link
          title={t('search-module.add-btn')}
          to={routerPath.CREATE_ISSUER}
          className="btn-icon btn btn-primary">
          <img src={IconPlus} className="img-fluid" alt="icon-plus" />
        </Link>
      )}
    </>
  )

  return (
    <div className="filter-data">
      <ul>
        <li>
          <div className="filter-box" ref={wrapperFilter}>
            <Button color="white" onClick={showFilter}>
              {t('badge-card.search-module.filter-text')}
              <img src={IconFilter} className="img-fluid" alt="icon-filter" />
            </Button>
            <div className={!isActive ? 'main-filter-box' : 'main-filter-box show'}>
              <div className="title-div">
                <div className="one">{t('badge-card.search-module.filter-text')}</div>
                <div className="two pointer">
                  <img
                    onClick={hideFilter}
                    src={IconClose}
                    className="img-fluid"
                    alt="icon-close"
                  />
                </div>
              </div>
              <div className="data-filter">
                <p className="span-10">{t('badge-card.search-module.filter-data-text')}</p>
                <Select
                  id="sort"
                  options={options}
                  isSearchable={true}
                  isMulti
                  isClearable={true}
                  className="react-select height-set"
                  classNamePrefix="select"
                  onChange={onChangeOfFilter}
                  placeholder={t('badge-card.search-module.filter-placeholder-text')}
                  value={localStorage.getItem('searchFilters') !== null ? searchFilters : null}
                />
              </div>
            </div>
          </div>
        </li>
        <li>
          <AwardOrders />
        </li>
        <li>
          <AddButton />
        </li>
      </ul>
    </div>
  )
}

export default FilterBox
