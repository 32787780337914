// Modules
import { Button, Modal, ModalBody } from 'reactstrap'
import Ripples from 'react-ripples'
import PropTypes from 'prop-types'
// Styles
import style from './Popup.css'

const Popup = ({
  show,
  changeSetShow,
  changeSetShowToFalse,
  onClick,
  headingText,
  paragraphText,
  buttonIsDisabled,
  buttonText
}) => {
  return (
    <Modal
      isOpen={show}
      toggle={changeSetShow}
      className="modal-dialog-centered common-modal"
      backdropClassName="op-35">
      <ModalBody>
        <div className="close-modal  pointer" onClick={changeSetShowToFalse}>
          <div className="icon-close" style={style.closeIcon} />
        </div>
        <div className="icon-modal">
          <div className="icon-warning" style={style.closeIcon} />
        </div>
        <div className="title">
          <h3 className="modal-title text-center">{headingText}</h3>
        </div>
        <p className="span-14 text-center mb-32">{paragraphText}</p>
        {onClick && (
          <div className="text-center">
            <Ripples
              className={`react-12-cut color-off-white ${buttonIsDisabled ? '' : 'disabled'}`}>
              <Button
                color="primary"
                className="modal-big-btn btn-cu-xl"
                onClick={onClick}
                disabled={!buttonIsDisabled}>
                {buttonText}
              </Button>
            </Ripples>
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

Popup.propTypes = {
  show: PropTypes.bool,
  changeSetShow: PropTypes.func,
  changeSetShowToFalse: PropTypes.func,
  onClick: PropTypes.func,
  headingText: PropTypes.string,
  paragraphText: PropTypes.string,
  buttonIsDisabled: PropTypes.bool,
  buttonText: PropTypes.string
}

export default Popup
