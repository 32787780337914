// Config
import { PATH, SIDEBAR, ACTION, rolesObj } from '../constants/constants'

const { ISSUERS, BADGES, AWARDS, SINGLE_AWARD, ORDERS } = PATH
const {
  DASHBOARD,
  USER_MANAGEMENT,
  CHAT_MANAGEMENT,
  OFFER_MANAGEMENT,
  OFFER_OVERVIEW,
  BILLING,
  MARKETING,
  OPEN_BADGES
} = SIDEBAR

/**
 * Available permissions for all users
 */
export const PERMISSIONS = {
  // superuser
  [rolesObj.superuser.key]: {
    PATH: {
      [ISSUERS]: {
        [ACTION.VIEW]: true,
        [ACTION.CREATE]: true,
        [ACTION.UPDATE]: true,
        [ACTION.DELETE]: true
      },
      [BADGES]: {
        [ACTION.VIEW]: true,
        [ACTION.VIEW_ACCOUNT_NUMBERS]: true,
        [ACTION.CREATE]: true,
        [ACTION.UPDATE]: true,
        [ACTION.DELETE]: true,
        [ACTION.BULK_MANAGE]: true
      },
      [AWARDS]: {
        [ACTION.VIEW]: true,
        [ACTION.CREATE]: true,
        [ACTION.DELETE]: true,
        [ACTION.SEND_MAIL]: true,
        [ACTION.SHARE]: true
      },
      [SINGLE_AWARD]: {
        [ACTION.VIEW]: true,
        [ACTION.CREATE]: true,
        [ACTION.UPDATE]: true,
        [ACTION.DELETE]: true,
        [ACTION.SEND_MAIL]: true,
        [ACTION.SHARE]: true
      },
      [ORDERS]: {
        [ACTION.VIEW]: true
      }
    },
    SIDEBAR: {
      [DASHBOARD]: true,
      [USER_MANAGEMENT]: true,
      [CHAT_MANAGEMENT]: true,
      [OFFER_MANAGEMENT]: true,
      [OFFER_OVERVIEW]: true,
      [BILLING]: true,
      [MARKETING]: true,
      [OPEN_BADGES]: true
    }
  },
  // dihkadmin
  [rolesObj.dihkadmin.key]: {
    PATH: {
      [ISSUERS]: {
        [ACTION.VIEW]: true,
        [ACTION.CREATE]: true,
        [ACTION.UPDATE]: true,
        [ACTION.DELETE]: true
      },
      [BADGES]: {
        [ACTION.VIEW]: true,
        [ACTION.VIEW_ACCOUNT_NUMBERS]: true,
        [ACTION.CREATE]: true,
        [ACTION.UPDATE]: true,
        [ACTION.DELETE]: true,
        [ACTION.BULK_MANAGE]: true
      },
      [AWARDS]: {
        [ACTION.VIEW]: true,
        [ACTION.CREATE]: true,
        [ACTION.DELETE]: true,
        [ACTION.SEND_MAIL]: true,
        [ACTION.SHARE]: true
      },
      [SINGLE_AWARD]: {
        [ACTION.VIEW]: true,
        [ACTION.CREATE]: true,
        [ACTION.DELETE]: true,
        [ACTION.SEND_MAIL]: true,
        [ACTION.SHARE]: true
      },
      [ORDERS]: {
        [ACTION.VIEW]: true
      }
    },
    SIDEBAR: {
      [DASHBOARD]: true,
      [USER_MANAGEMENT]: true,
      [CHAT_MANAGEMENT]: true,
      [OFFER_MANAGEMENT]: true,
      [OFFER_OVERVIEW]: true,
      [BILLING]: true,
      [MARKETING]: true,
      [OPEN_BADGES]: true
    }
  },
  // dihkemployee
  [rolesObj.dihkemployee.key]: {
    PATH: {
      [ISSUERS]: {
        [ACTION.VIEW]: true,
        [ACTION.CREATE]: false,
        [ACTION.UPDATE]: false,
        [ACTION.DELETE]: false
      },
      [BADGES]: {
        [ACTION.VIEW]: true,
        [ACTION.VIEW_ACCOUNT_NUMBERS]: true,
        [ACTION.CREATE]: false,
        [ACTION.UPDATE]: false,
        [ACTION.DELETE]: false,
        [ACTION.BULK_MANAGE]: false
      },
      [AWARDS]: {
        [ACTION.VIEW]: true,
        [ACTION.CREATE]: false,
        [ACTION.DELETE]: false,
        [ACTION.SEND_MAIL]: true,
        [ACTION.SHARE]: true
      },
      [SINGLE_AWARD]: {
        [ACTION.VIEW]: true,
        [ACTION.CREATE]: true,
        [ACTION.DELETE]: true,
        [ACTION.SEND_MAIL]: true,
        [ACTION.SHARE]: true
      },
      [ORDERS]: {
        [ACTION.VIEW]: true
      }
    },
    SIDEBAR: {
      [DASHBOARD]: true,
      [USER_MANAGEMENT]: true,
      [CHAT_MANAGEMENT]: false,
      [OFFER_MANAGEMENT]: true,
      [OFFER_OVERVIEW]: false,
      [BILLING]: false,
      [MARKETING]: true,
      [OPEN_BADGES]: true
    }
  },
  // ihkadmin
  [rolesObj.ihkadmin.key]: {
    PATH: {
      [ISSUERS]: {
        [ACTION.VIEW]: false,
        [ACTION.CREATE]: false,
        [ACTION.UPDATE]: false,
        [ACTION.DELETE]: false
      },
      [BADGES]: {
        [ACTION.VIEW]: true,
        [ACTION.VIEW_ACCOUNT_NUMBERS]: false,
        [ACTION.CREATE]: false,
        [ACTION.UPDATE]: false,
        [ACTION.DELETE]: false,
        [ACTION.ORDER_BADGE]: true,
        [ACTION.BULK_MANAGE]: false
      },
      [AWARDS]: {
        [ACTION.VIEW]: true,
        [ACTION.CREATE]: false,
        [ACTION.DELETE]: false,
        [ACTION.SEND_MAIL]: true,
        [ACTION.SHARE]: true
      },
      [SINGLE_AWARD]: {
        [ACTION.VIEW]: false,
        [ACTION.CREATE]: false,
        [ACTION.DELETE]: false,
        [ACTION.SEND_MAIL]: true,
        [ACTION.SHARE]: true
      },
      [ORDERS]: {
        [ACTION.VIEW]: false
      }
    },
    SIDEBAR: {
      [DASHBOARD]: true,
      [USER_MANAGEMENT]: true,
      [CHAT_MANAGEMENT]: false,
      [OFFER_MANAGEMENT]: true,
      [OFFER_OVERVIEW]: false,
      [BILLING]: true,
      [MARKETING]: true,
      [OPEN_BADGES]: true
    }
  },
  // ihkagent
  [rolesObj.ihkagent.key]: {
    PATH: {
      [ISSUERS]: {
        [ACTION.VIEW]: false,
        [ACTION.CREATE]: false,
        [ACTION.UPDATE]: false,
        [ACTION.DELETE]: false
      },
      [BADGES]: {
        [ACTION.VIEW]: true,
        [ACTION.VIEW_ACCOUNT_NUMBERS]: false,
        [ACTION.CREATE]: false,
        [ACTION.UPDATE]: false,
        [ACTION.DELETE]: false,
        [ACTION.ORDER_BADGE]: true,
        [ACTION.BULK_MANAGE]: false
      },
      [AWARDS]: {
        [ACTION.VIEW]: true,
        [ACTION.CREATE]: false,
        [ACTION.DELETE]: false,
        [ACTION.SEND_MAIL]: true,
        [ACTION.SHARE]: true
      },
      [SINGLE_AWARD]: {
        [ACTION.VIEW]: false,
        [ACTION.CREATE]: false,
        [ACTION.DELETE]: false,
        [ACTION.SEND_MAIL]: true,
        [ACTION.SHARE]: true
      },
      [ORDERS]: {
        [ACTION.VIEW]: false
      }
    },
    SIDEBAR: {
      [DASHBOARD]: true,
      [USER_MANAGEMENT]: true,
      [CHAT_MANAGEMENT]: false,
      [OFFER_MANAGEMENT]: true,
      [OFFER_OVERVIEW]: false,
      [BILLING]: true,
      [MARKETING]: true,
      [OPEN_BADGES]: true
    }
  },
  // cooppartneradmin
  [rolesObj.cooppartneradmin.key]: {
    PATH: {
      [ISSUERS]: {
        [ACTION.VIEW]: false,
        [ACTION.CREATE]: false,
        [ACTION.UPDATE]: false,
        [ACTION.DELETE]: false
      },
      [BADGES]: {
        [ACTION.VIEW]: true,
        [ACTION.VIEW_ACCOUNT_NUMBERS]: false,
        [ACTION.CREATE]: false,
        [ACTION.UPDATE]: false,
        [ACTION.DELETE]: false,
        [ACTION.ORDER_BADGE]: true,
        [ACTION.BULK_MANAGE]: false
      },
      [AWARDS]: {
        [ACTION.VIEW]: true,
        [ACTION.CREATE]: false,
        [ACTION.DELETE]: false,
        [ACTION.SEND_MAIL]: true,
        [ACTION.SHARE]: true
      },
      [SINGLE_AWARD]: {
        [ACTION.VIEW]: false,
        [ACTION.CREATE]: false,
        [ACTION.DELETE]: false,
        [ACTION.SEND_MAIL]: true,
        [ACTION.SHARE]: true
      },
      [ORDERS]: {
        [ACTION.VIEW]: false
      }
    },
    SIDEBAR: {
      [DASHBOARD]: true,
      [USER_MANAGEMENT]: true,
      [CHAT_MANAGEMENT]: false,
      [OFFER_MANAGEMENT]: true,
      [OFFER_OVERVIEW]: false,
      [BILLING]: true,
      [MARKETING]: true,
      [OPEN_BADGES]: true
    }
  },
  // cooppartneragent
  [rolesObj.cooppartneragent.key]: {
    PATH: {
      [ISSUERS]: {
        [ACTION.VIEW]: false,
        [ACTION.CREATE]: false,
        [ACTION.UPDATE]: false,
        [ACTION.DELETE]: false
      },
      [BADGES]: {
        [ACTION.VIEW]: true,
        [ACTION.VIEW_ACCOUNT_NUMBERS]: false,
        [ACTION.CREATE]: false,
        [ACTION.UPDATE]: false,
        [ACTION.DELETE]: false,
        [ACTION.ORDER_BADGE]: true,
        [ACTION.BULK_MANAGE]: false
      },
      [AWARDS]: {
        [ACTION.VIEW]: true,
        [ACTION.CREATE]: false,
        [ACTION.DELETE]: false,
        [ACTION.SEND_MAIL]: true,
        [ACTION.SHARE]: true
      },
      [SINGLE_AWARD]: {
        [ACTION.VIEW]: false,
        [ACTION.CREATE]: false,
        [ACTION.DELETE]: false,
        [ACTION.SEND_MAIL]: true,
        [ACTION.SHARE]: true
      },
      [ORDERS]: {
        [ACTION.VIEW]: false
      }
    },
    SIDEBAR: {
      [DASHBOARD]: true,
      [USER_MANAGEMENT]: true,
      [CHAT_MANAGEMENT]: false,
      [OFFER_MANAGEMENT]: true,
      [OFFER_OVERVIEW]: false,
      [BILLING]: true,
      [MARKETING]: true,
      [OPEN_BADGES]: true
    }
  }
}

/**
 * Gets available CRUD for the user, based on its role permissions.
 *
 * @param {String} userRole the user role
 * @param {String} path of the view where the permissions are needed
 * @param {String} action the role can have pemissions for
 * @returns a bolean true or false
 */
export function hasPermission(userRole, path, action) {
  return PERMISSIONS[userRole]?.PATH?.[path]?.[action]
}

/**
 * Gets available MENU access for the user, based on its role permissions.
 *
 * @param {String} userRole the user role
 * @param {String} menu sidebar menu
 * @returns a bolean true or false
 */
export function hasAccess(userRole, menu) {
  return PERMISSIONS[userRole]?.SIDEBAR?.[menu]
}
