// Modules
import { useCallback, useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import get from 'lodash.get'
// Actions
import { useAuth } from '../../../ducks/Auth.ducks'
import { getOrders, deleteOrdersById } from '../../../ducks/Orders.ducks'
// Components
import { HeaderTitle, PaginationLoad, Popup as DeleteOrdersPopup } from '../../../components'
import { OrdersList } from '../components'
import FilterPopupComponent from '../../../components/FilterPopup'
import FilterAndActions from '../components/FilterAndActions'
import AwardOrderApproval from '../AwardOrderApproval'
// Styled Components
import { BackLink, GoBack, SubHeader, TopHeader } from './AwardOrdersListingStyled'
// Constants
import { hasPermission } from '../../../helpers/permissions'
import { ACTION, PATH } from '../../../constants/constants'
// Icons
import { BsArrowLeft } from 'react-icons/bs'
import { FaCheck, FaExclamation } from 'react-icons/fa'

const generateOrderIssuersFilters = (ordersData) => {
  const uniqueIssuers = new Map()

  ordersData?.forEach((order) => {
    const { id, name } = order.issuer
    if (!uniqueIssuers.has(id)) {
      uniqueIssuers.set(id, { id, value: name, label: name })
    }
  })

  return Array.from(uniqueIssuers.values())
}

const AwardOrdersListing = ({ getOrders }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { t } = useTranslation()
  const { authState: { auth } = {} } = useAuth()
  const { loading, ordersData } = useSelector((state) => state.orders)

  const filterRef = useRef(null)
  const [orders, setOrders] = useState(ordersData || [])
  const [selectedOrdersForDeletion, setSelectedOrdersDeletion] = useState([])
  const [orderForApproval, setOrderForApproval] = useState({})
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false)

  const [filterOptions, setFilterOptions] = useState({
    isOpen: false,
    selected: []
  })

  useEffect(() => {
    if (!hasPermission(auth.role, PATH.ORDERS, ACTION.VIEW)) {
      history.replace(`/?token=${auth?.authToken}`)
      return
    }

    getOrders()
  }, [auth.role, auth.authToken, getOrders])

  useEffect(() => {
    if (ordersData !== orders) {
      setOrders(ordersData)
    }
  }, [ordersData])

  const onFilterChange = (value) => {
    const selectedIssuerIds = value.map((ele) => ele.id)

    setOrders((prevOrders) =>
      prevOrders.map((order) => ({
        ...order,
        selected: selectedIssuerIds.includes(order.issuer.id)
      }))
    )
    setFilterOptions((prevFilter) => ({
      ...prevFilter,
      selected: value
    }))
  }

  // Derived filtered list
  const filteredOrders = orders?.some((order) => order.selected)
    ? orders.filter((order) => order.selected)
    : orders

  const updateSelectedOrders = ({ operation, orderId }) => {
    if (!['unselectAll', 'selectAll', 'select', 'unselect'].includes(operation)) return
    if (['select', 'unselect'].includes(operation) && !orderId) return

    let newSelectedOrders = selectedOrdersForDeletion

    switch (operation) {
      case 'selectAll':
        newSelectedOrders = filteredOrders?.map((order) => get(order, 'id')) || []
        break
      case 'unselectAll':
        newSelectedOrders = []
        break
      case 'select':
        if (!selectedOrdersForDeletion.includes(orderId)) {
          newSelectedOrders = [...selectedOrdersForDeletion, orderId]
        }
        break
      case 'unselect':
        newSelectedOrders = selectedOrdersForDeletion.filter((id) => id !== orderId)
        break
    }

    setSelectedOrdersDeletion(newSelectedOrders)
  }

  const handleOrderGroupSelection = (groupSelection, partialSelection) => {
    updateSelectedOrders({
      operation: groupSelection || partialSelection ? 'unselectAll' : 'selectAll'
    })
  }

  const handleSetOrderForApprovalModal = useCallback((order) => {
    setOrderForApproval(order)
    setIsApprovalModalOpen(true)
  }, [])

  const handleDeleteOrders = async () => {
    deleteOrdersById &&
      deleteOrdersById({
        orderIds: selectedOrdersForDeletion,
        dispatch,
        successCb: () => {
          setIsDeleteModalOpen(false)
          getOrders()
          setSelectedOrdersDeletion([])
          toast.success(t('awardOrders.listing.messages.delete-orders-successfully'), {
            icon: <FaCheck />
          })
        },
        errorCb: () => {
          toast.error(t('awardOrders.listing.messages.delete-orders-error'), {
            icon: <FaExclamation />
          })
        }
      })
  }

  return (
    <>
      <Helmet>
        <title>IHK - {t('awardOrders.general.breadcrums.title')}</title>
      </Helmet>
      <section id="orders" className="mid-section">
        <div className="container-fluid common-container" style={{ position: 'relative' }}>
          <HeaderTitle title="Badges" />

          <GoBack>
            <TopHeader>
              <BackLink to={`/?token=${auth?.authToken}`}>
                <BsArrowLeft />
                {t('awardOrders.general.breadcrums.title')}
              </BackLink>
            </TopHeader>
            <SubHeader>{t('awardOrders.general.breadcrums.subtitle')}</SubHeader>
          </GoBack>

          <FilterAndActions
            filterRef={filterRef}
            setFilterOptions={setFilterOptions}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            selectedOrders={selectedOrdersForDeletion}
          />

          <FilterPopupComponent
            isOpen={filterOptions.isOpen}
            selected={filterOptions.selected}
            options={generateOrderIssuersFilters(ordersData)}
            onChange={onFilterChange}
            onClose={() => {
              setFilterOptions((prev) => ({
                ...prev,
                isOpen: !prev.isOpen
              }))
            }}
          />

          {loading ? (
            <PaginationLoad />
          ) : (
            <OrdersList
              orders={filteredOrders}
              selectedOrders={selectedOrdersForDeletion}
              handleOrderGroupSelection={handleOrderGroupSelection}
              updateSelectedOrders={updateSelectedOrders}
              setOrderForApproval={handleSetOrderForApprovalModal}
            />
          )}
        </div>
      </section>

      <AwardOrderApproval
        isOpen={isApprovalModalOpen}
        toggle={() => setIsApprovalModalOpen((prev) => !prev)}
        order={orderForApproval}
        getOrders={getOrders}
      />

      <DeleteOrdersPopup
        show={isDeleteModalOpen}
        onClick={handleDeleteOrders}
        changeSetShowToFalse={() => setIsDeleteModalOpen((prev) => !prev)}
        buttonIsDisabled={true}
        headingText={t('awardOrders.listing.modal.dialog-title')}
        paragraphText={t('awardOrders.listing.modal.dialog-body')}
        buttonText={t('awardOrders.listing.modal.dialog-delete-button')}
      />
    </>
  )
}

export default connect(null, { getOrders })(AwardOrdersListing)
