// Modules
import { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
// Actions
import { useAuth } from '../../../ducks/Auth.ducks'
import { getIssuers } from '../../../ducks/Issuers.ducks'
// Component
import { HeaderTitle, PaginationLoad } from '../../../components'
import IssuerListing from './IssuerListing'
// Style
import './issuerListing.scss'
import { getOrders } from '../../../ducks/Orders.ducks'

const IssuersListingMain = ({ getIssuers, getOrders }) => {
  const { t } = useTranslation()
  const { authState: { auth } = {} } = useAuth()
  const { loading, issuersList: issuers, stats } = useSelector((state) => state.issuers)

  useEffect(() => {
    getIssuers()
    getOrders()
  }, [getIssuers, getOrders])

  return (
    <>
      <Helmet>
        <title>IHK - Aussteller Auflistung</title>
      </Helmet>
      <section id="issuerScroll" className="mid-section">
        <div className="container-fluid common-container">
          <HeaderTitle title={t('page-title.main-badge')} />
          <IssuerListing t={t} auth={auth} issuers={issuers} stats={stats} />
          {loading && <PaginationLoad />}
        </div>
      </section>
    </>
  )
}

export default connect(null, { getIssuers, getOrders })(IssuersListingMain)
