import styled, { keyframes } from 'styled-components'
import colors from '../../helpers/colors'

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`

export const TokenDisplay = styled.div`
  display: flex;
  align-items: center;
  background-color: #f7f9fc;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;

  &:focus-within {
    box-shadow: 0 0 0 2px #3498db;
  }
`

export const TokenInput = styled.input.attrs(({ inputType }) => ({
  type: inputType || 'text'
}))`
  flex-grow: 1;
  padding: 16px;
  border: none;
  font-size: 14px;
  color: #2c3e50;
  background-color: transparent;

  &:focus {
    outline: none;
  }
`

export const TokenActions = styled.div`
  display: flex;
  gap: 8px;
  padding-right: 8px;
`

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.fullWidth ? '12px 20px' : '8px')};
  background-color: ${(props) => {
    if (props.success) return '#2ecc71'
    if (props.danger) return '#e74c3c'
    if (props.primary) return colors.blue
    return 'transparent'
  }};
  color: ${(props) => (props.primary || props.success || props.danger ? 'white' : '#34495e')};
  border: none;
  border-radius: ${(props) => (props.fullWidth ? '8px' : '50%')};
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: 600;
  ${(props) => props.fullWidth && 'width: 100%;'}

  &:hover {
    background-color: ${(props) => {
      if (props.success) return '#27ae60'
      if (props.danger) return '#c0392b'
      if (props.primary) return colors.darkSlateBlue
      return '#f1f3f5'
    }};
  }

  svg {
    width: 18px;
    height: 18px;
    ${(props) => props.fullWidth && 'margin-right: 8px;'}
  }
`

export const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
`

export const GenerationScreen = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;

  h4 {
    font-size: 16px;
    color: #23366c;
    margin: 0;
  }

  p {
    font-size: 13px;
    color: #616e91;
    margin: 0;
  }
`

export const InfoMessage = styled.p`
  color: #7f8c8d;
  font-size: 14px;
  text-align: center;
  margin-top: 15px;
  animation: ${fadeIn} 0.3s ease-out;
`

export const FormGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const Label = styled.label`
  font-size: 13px;
  font-weight: 600;
  color: #34495e;
`

export const SelectWrapper = styled.div`
  position: relative;
  flex-grow: 1;
`

export const Select = styled.select`
  appearance: none;
  width: 100%;
  padding: 6px 10px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  font-size: 14px;
  color: #23366c;
  background-color: #fff;
  transition: all 0.3s ease;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  }
`

export const ChevronIcon = styled.span`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;

  &::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #2c3e50;
  }
`

export const ExpirationStatus = styled.div`
  background-color: ${(props) => (props.expired ? '#fff5f5' : '#f7f9fc')};
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 14px;
  color: #23366c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  strong {
    font-weight: 600;
    color: ${(props) => (props.expired ? '#e74c3c' : '#23366c')};
  }
`

export const GenerateIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
    />
  </svg>
)

export const CopyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
    />
  </svg>
)

export const DeleteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
    />
  </svg>
)
